@font-face {
  font-family: Segoe;
  font-weight: normal; /* 400 */
  src: url(/fonts/segoe/SegoeUI.ttf);
}

@font-face {
  font-family: Segoe;
  font-weight: 500; /* 400 */
  src: url(/fonts/segoe/SegoeUI.ttf);
}


@font-face {
  font-family: Segoe;
  font-weight: bold; /* 400 */
  src: url(/fonts/segoe/SegoeUI.ttf);
}
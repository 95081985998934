/* Generated from https://gwfh.mranftl.com/fonts */

/* mukta-regular - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/mukta/mukta-v14-latin_latin-ext-regular.woff2') format('woff2'),
    url('/fonts/mukta/mukta-v14-latin_latin-ext-regular.ttf') format('truetype');
}

/* mukta-500 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/mukta/mukta-v14-latin_latin-ext-500.woff2') format('woff2'),
    url('/fonts/mukta/mukta-v14-latin_latin-ext-500.ttf') format('truetype');
}

/* mukta-700 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/mukta/mukta-v14-latin_latin-ext-700.woff2') format('woff2'),
    url('/fonts/mukta/mukta-v14-latin_latin-ext-700.ttf') format('truetype');
}

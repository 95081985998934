@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 400;
  src: url("/fonts/inter/Inter-UI-Regular.woff2") format("woff2"),
       url("/fonts/inter/Inter-UI-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 400;
  src: url("/fonts/inter/Inter-UI-Italic.woff2") format("woff2"),
       url("/fonts/inter/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 500;
  src: url("/fonts/inter/Inter-UI-Medium.woff2") format("woff2"),
       url("/fonts/inter/Inter-UI-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 500;
  src: url("/fonts/inter/Inter-UI-MediumItalic.woff2") format("woff2"),
       url("/fonts/inter/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 700;
  src: url("/fonts/inter/Inter-UI-Bold.woff2") format("woff2"),
       url("/fonts/inter/Inter-UI-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 700;
  src: url("/fonts/inter/Inter-UI-BoldItalic.woff2") format("woff2"),
       url("/fonts/inter/Inter-UI-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 900;
  src: url("/fonts/inter/Inter-UI-Black.woff2") format("woff2"),
       url("/fonts/inter/Inter-UI-Black.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 900;
  src: url("/fonts/inter/Inter-UI-BlackItalic.woff2") format("woff2"),
       url("/fonts/inter/Inter-UI-BlackItalic.woff") format("woff");
}

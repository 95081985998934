body {
  margin: 0;
  padding: 0;
  font-family: "Mukta", sans-serif;
}

#root {
  width: auto;
  min-height: 100vh;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: auto;
}

#background {
  position: fixed;
  bottom: 0;
  top: 0px;
  left: 0;
  right: 0;
  z-index: -2;
  background: linear-gradient(to bottom, #118FCB 64px, #062D44 100%);
}

#background::after {
  content: '';
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-image: url('/bg-neg.png');
  background-repeat: repeat-x;
  background-position: right bottom;
  background-size: auto 200px;
  opacity: 0.15;
}

#api-docs {
  background: white
}

a, a:visited, a:active, a:hover{
  color: inherit;
  text-decoration: none;
  outline: 0;
}
